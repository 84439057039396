const keypointColor = "rgba(0, 255, 0, 1)";
const boxColor = "#f00";

export default function drawKeypoints(ctx: any, prediction: any) {
  drawBox(ctx, prediction);
  drawFaceMesh(ctx, prediction);
}

function drawBox(ctx: any, prediction: any) {
  const x = prediction.box.xMin;
  const y = prediction.box.yMin;
  const width = prediction.box.width;
  const height = prediction.box.height;

  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.strokeStyle = boxColor;
  ctx.stroke();
}

function drawFaceMesh(ctx: any, prediction: any) {
  prediction.keypoints.forEach((item: any, index: string) => {
    const x = item.x;
    const y = item.y;

    ctx.fillRect(x, y, 2, 2);
    ctx.fillStyle = keypointColor;
  });
}
