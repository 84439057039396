// @ts-nocheck
import { Suspense, useEffect, useRef, useState } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { Box, OrbitControls, Plane, Shadow, Sphere } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh, MathUtils } from "three";

export default function Scene({
  mouthMorphTargetInfluences,
  leftEyeMorphTargetInfluences,
  rightEyeMorphTargetInfluences,
  headRotation,
  headPosition,
}: any) {
  const sphereRef = useRef<Mesh>(null!);
  const boxRef = useRef<Mesh>(null!);
  const suzanneRef = useRef<Mesh>(null!);
  const gltf = useLoader(GLTFLoader, `/alligator.glb`);

  useEffect(() => {
    if (suzanneRef.current) {
      const alligator = suzanneRef.current.children
        .find(({ name }) => name === "alligator_armature")
        .children.find(({ name }) => name === "alligator");
      alligator.morphTargetInfluences = mouthMorphTargetInfluences;
      const rightEyelid = suzanneRef.current.children.find(
        ({ name }) => name === "right_eyelid"
      );
      const leftEyelid = suzanneRef.current.children.find(
        ({ name }) => name === "left_eyelid"
      );
      rightEyelid.morphTargetInfluences = rightEyeMorphTargetInfluences;
      leftEyelid.morphTargetInfluences = leftEyeMorphTargetInfluences;
    }
    // gltf.scene.traverse(function (node) {
    //   if (node.hasOwnProperty("isMesh")) {
    //     node.castShadow = true;
    //     node.receiveShadow = true;
    //   }
    // });
  }, [mouthMorphTargetInfluences]);

  return (
    <>
      {/* <pointLight
        intensity={0.15}
        position={[2, 0, 8]}
        color={"#ffaaaa"}
        castShadow
        shadowBias={1}
      /> */}
      {/* <ambientLight intensity={0.24} /> */}
      <directionalLight
        position={[-2, 12, 0]}
        color={"#ccccee"}
        intensity={0.3}
        castShadow
        shadowBias={1}
        // shadowMapWidth={2048}
        // shadowMapHeight={2048}
      />
      <group position={[-1.2, 0.6, 0]} rotation={[-0.4, 0.1, 0]}>
        <Suspense fallback={null}>
          <primitive
            castShadow
            receiveShadow
            ref={suzanneRef}
            object={gltf.scene}
            position={headPosition}
            rotation={headRotation}
            color={"#ff0000"}
          />
        </Suspense>
      </group>
    </>
  );
}
