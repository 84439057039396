export default function calculateFaceAngle(mesh: any) {
  const leftEye = mesh.find(({ name }: any) => name === "leftEye");
  const rightEye = mesh.find(({ name }: any) => name === "rightEye");
  const faceLeft = mesh.find(({ name }: any, idx: number) => idx === 50);
  const faceTop = mesh[10];
  const faceBottom = mesh[152];

  const angle = {
    roll: Math.atan2(leftEye.x - leftEye.z, rightEye.x - rightEye.y) - 2,
    yaw: Math.atan2(faceLeft.z, faceLeft.x) * -4,
    pitch: Math.atan2(faceTop.y - faceTop.z, faceBottom.y - faceBottom.z),
  };

  return angle;
}
