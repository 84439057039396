import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment, Sky } from "@react-three/drei";
import { useInterval, useTimeout } from "usehooks-ts";
import "@mediapipe/face_mesh";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import Scene from "./scene";
import calculateFaceAngle from "./calculate-face-angle";
import drawKeypoints from "./draw-keypoints";

enum displayModeTypes {
  LANDMARKS = "Landmarks",
  ALIEN = "Alien",
  ALLIGATOR = "Alligator",
}

const faceKeypointMap = {
  lipUpper: 13,
  lipLower: 14,
  rightEyeUpper: 386,
  rightEyeLower: 374,
  leftEyeUpper: 159,
  leftEyeLower: 145,
};

function calculateDistance(p1: any, p2: any) {
  var a = p2.x - p1.x;
  var b = p2.y - p1.y;
  var c = p2.z - p1.z;

  return Math.sqrt(a * a + b * b + c * c);
}

function createFaceDetector() {
  const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
  const detectorConfig = {
    runtime: "mediapipe",
    solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
  };

  const promise = new Promise((resolve, reject) => {
    faceLandmarksDetection
      .createDetector(
        model,
        // @ts-ignore
        detectorConfig
      )
      .then(resolve);
  });

  return promise;
}

const ARFaceMask = () => {
  const [faceDetector, setFaceDetector] = useState<any>(null);
  const [headRotation, setHeadRotation] = useState([0, 0, 0]);
  const [headPosition, setHeadPosition] = useState<Array<number>>([0, 0, 0]);
  const [detectionRuntimeDelay, setDetectionRuntimeDelay] =
    useState<number>(10);
  const [mouthMorphTargetInfluences, setMouthMorphTargetInfluences] = useState([
    0,
  ]);
  const [leftEyeMorphTargetInfluences, setLeftEyeMorphTargetInfluences] =
    useState([0]);
  const [rightEyeMorphTargetInfluences, setRightEyeMorphTargetInfluences] =
    useState([0]);
  const [displayMode, setDisplayMode] = useState(displayModeTypes.ALIEN);
  const webcam = useRef<Webcam>(null);
  const canvas = useRef<HTMLCanvasElement>(null);

  useTimeout(() => {
    createFaceDetector().then(setFaceDetector);
  }, 10);

  useInterval(
    () => {
      if (faceDetector && webcam.current?.video?.readyState) {
        detect(faceDetector);
      }
    },
    true ? detectionRuntimeDelay : null
  );

  const detect = async (detector: any) => {
    if (webcam.current && detector) {
      const webcamCurrent = webcam.current as any;
      const video = webcamCurrent.video;
      const videoWidth = webcamCurrent.video.videoWidth;
      const videoHeight = webcamCurrent.video.videoHeight;

      if (webcamCurrent.video.readyState === 4) {
        const [prediction] = await detector.estimateFaces(video, {
          flipHorizontal: true,
        });

        if (prediction) {
          const { keypoints } = prediction;
          const { roll, pitch, yaw } = calculateFaceAngle(keypoints);
          const [
            lipUpper,
            lipLower,
            rightEyeUpper,
            rightEyeLower,
            leftEyeUpper,
            leftEyeLower,
          ] = [
            keypoints[faceKeypointMap.lipUpper],
            keypoints[faceKeypointMap.lipLower],
            keypoints[faceKeypointMap.rightEyeUpper],
            keypoints[faceKeypointMap.rightEyeLower],
            keypoints[faceKeypointMap.leftEyeUpper],
            keypoints[faceKeypointMap.leftEyeLower],
          ];
          const videoSize = webcamCurrent.video.getBoundingClientRect();
          const mouthMorphInfluence =
            (Math.round(calculateDistance(lipLower, lipUpper) * 10) / 300) *
            0.8;
          const rightEyeMorphTargetInfluence =
            Math.round(calculateDistance(rightEyeLower, rightEyeUpper) - 6) /
              3 -
            0.3;
          const leftEyeMorphTargetInfluence =
            Math.round(calculateDistance(leftEyeLower, leftEyeUpper) - 6) / 3 -
            0.3;
          const { xMin, width, yMin, height } = prediction.box;
          const { height: webcamHeight, width: webcamWidth } = videoSize;
          const xPosition = ((xMin + width / 2) / webcamWidth) * 4 - 2;
          const yPosition = ((yMin + height / 2) / webcamHeight) * -2 + 1.3; // * -3 - 1;

          setMouthMorphTargetInfluences([mouthMorphInfluence]);
          setRightEyeMorphTargetInfluences([
            rightEyeMorphTargetInfluence < 0 ? 0 : rightEyeMorphTargetInfluence,
          ]);
          setLeftEyeMorphTargetInfluences([
            leftEyeMorphTargetInfluence < 0 ? 0 : leftEyeMorphTargetInfluence,
          ]);
          setHeadPosition([xPosition, yPosition, 0]);

          if (
            Math.abs(yaw - headRotation[1]) > 0.01 ||
            Math.abs(pitch - headRotation[0]) > 0.01
          ) {
            setHeadRotation([
              pitch < 0 ? 0 : pitch,
              yaw < -0.5 ? -0.5 : yaw,
              0, //(roll < 1.7 ? roll : 1.7) - 1.6,
            ]);
          }

          if (displayMode === displayModeTypes.LANDMARKS && canvas.current) {
            canvas.current.width = videoWidth;
            canvas.current.height = videoHeight;
            const ctx = canvas.current?.getContext("2d");
            requestAnimationFrame(() => drawKeypoints(ctx, prediction));
          }
        }
        // setTimeout(() => detect(detector), 10);
      } else {
        // setTimeout(() => detect(detector), 250);
      }
    }
  };

  // useEffect(() => {
  //   runDetection();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [webcam.current?.video?.readyState]);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          background: "#fff",
          border: "1px solid #000",
          zIndex: 100000,
          position: "absolute",
          display: "none",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        {Object.values(displayModeTypes).map((displayModeType) => (
          <label
            htmlFor={displayModeType}
            style={{ padding: "5px" }}
            key={displayModeType}
          >
            <input
              type="radio"
              id={displayModeType}
              name="display-mode"
              defaultChecked={displayMode === displayModeType}
              onChange={(e) => setDisplayMode(displayModeType)}
            />
            {displayModeType}
          </label>
        ))}
        {/* <label
          htmlFor="landmarks"
          style={{ padding: "5px" }}
          onClick={() => setDisplayMode}
        >
          <input type="radio" id="landmarks" name="display-mode" />
          {"Landmarks"}
        </label>
        <label htmlFor="alien" style={{ padding: "5px" }}>
          <input type="radio" id="alien" name="display-mode" />
          <label htmlFor="alien">{"Alien"}</label>
        </label>
        <label htmlFor="alligator" style={{ padding: "5px" }}>
          <input type="radio" id="alligator" name="display-mode" />
          <label htmlFor="alligator">{"Alligator"}</label>
        </label> */}
      </div>
      <Webcam
        audio={false}
        ref={webcam}
        mirrored={true}
        width="400"
        height="300"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          // visibility:
          //   displayMode === displayModeTypes.LANDMARKS ? "visible" : "hidden",
        }}
      />
      {displayMode !== displayModeTypes.LANDMARKS && (
        <Canvas
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            // background: "#000",
            width: webcam.current
              ? webcam.current.video?.getBoundingClientRect().width
              : "",
            height: webcam.current
              ? webcam.current.video?.getBoundingClientRect().height
              : "",
            // visibility: "hidden",
          }}
          camera={{ position: [0, 0, 12], fov: 15 }}
          shadows
        >
          {/* <Sky sunPosition={[100, 10, 100]} distance={1000} /> */}
          <Environment files="lauter_waterfall_1k.hdr" />
          <OrbitControls />
          <Scene
            mouthMorphTargetInfluences={mouthMorphTargetInfluences}
            leftEyeMorphTargetInfluences={leftEyeMorphTargetInfluences}
            rightEyeMorphTargetInfluences={rightEyeMorphTargetInfluences}
            headRotation={headRotation}
            headPosition={headPosition}
          />
        </Canvas>
      )}
      {displayMode === displayModeTypes.LANDMARKS && (
        <canvas
          ref={canvas}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: webcam.current
              ? webcam.current.video?.getBoundingClientRect().width
              : "",
            height: webcam.current
              ? webcam.current.video?.getBoundingClientRect().height
              : "",
          }}
        />
      )}
    </div>
  );
};

export default ARFaceMask;
