import React from "react";
import logo from "./logo.svg";
import "./App.css";
import ARFaceMask from "./ar-face-mask";

function App() {
  return <ARFaceMask />;
}

export default App;
